<template>
  <div class="outer">
    <div class="formBox">
      <el-form
        :inline="true"
        :model="searchFormData"
        class="demo-form-inline"
        size="mini"
      >
        <el-form-item :label="$t('label.label') + '：'">
          <el-input
            style="width: 200px"
            @change="dateChange"
            prop="workOrderNo"
            v-model="searchFormData.condition.compName"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('homepage.components.creationtime') + '：'">
          <el-date-picker
            style="width: 350px"
            v-model="submitTime"
            type="datetimerange"
            align="right"
            unlink-panels
            :range-separator="$t('label.forecast.customforecast.submit.zhi')"
            :start-placeholder="$t('label.starttime')"
            :end-placeholder="$t('label.endtime')"
            value-format="yyyy-MM-dd HH:mm:ss"
            @change="dateChange"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </div>

    <!-- 表格数据 -->
    <div class="cusTable">
      <dynamic-table
        class="myTable"
        :height="tableHeight"
        :tableHeader="tableData.title"
        :table-data="tableData.data"
        @loadScroll="loadScroll"
        :loading="loading"
        ref="dynamicTable"
        :isShowOpration="true"
        :oprationBtnList="oprationBtnList"
        @del="del"
        @handleClick="handleClick"
        :isClick="clickFlag"
      ></dynamic-table>
    </div>
    <!--复制自定义组件弹框 -->
    <!-- <el-dialog title="" :visible.sync="dialogFormVisible" width="400px" center>
      <el-form :model="form">
        <el-form-item label="组件名称:">
          <el-input
            v-model="form.compName"
            autocomplete="off"
            style="width: 200px"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogFormVisible = false"
          >取 消</el-button
        >
        <el-button size="mini" type="primary" @click="handleCopyCustomComp"
          >确 定</el-button
        >
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import dynamicTable from "../Table/v-dynamicTable";
import dayjs from "dayjs";
export default {
  components: {
    dynamicTable,
  },
  data() {
    return {
      tableData: {
        data: [],
        title: [],
      },
      oprationBtnList: [
        { action: "del", label: this.$i18n.t("pagecreator_page_button_delete") },
      ],
      tableHeight: "", //table的高度
      clickFlag: true,
      submitTime: [],
      // 查询条件参数
      searchFormData: {
        pageNo: 1,
        pageSize: 20,
        condition: {
          compName: "", // 关键字
          dtBegin: "", // 开始时间
          dtEnd: "", // 结束时间
        },
      },
      indexPage: 0, //页数++
      loading: false,
      refreshDate: "", //
      // form: {
      //   compName: "", //组件名称
      //   id: "",
      // },
      // dialogFormVisible: false, //复制组件
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    /* 查询自定义组件 */
    getTableData() {
      this.loading = true;
      this.$devHttp
        .postFormat(
          `${window.Glod['ccex-dev']}/custom/pc/1.0/post/pageCustomComp`,
          this.searchFormData
        )
        .then(
          (res) => {
            //赋值表头
            this.tableData.title = res.data.title.map((item) => {
              if (item.title) {
                item.title = this.$i18n.t(item.title) || item.title;
              }
              if (item.prop == "createDate" || item.prop == "lastModifyDate") {
                item.sortable = true
              }
              return item;
            });
            let data = res.data.list;
            // 处理日期
            for (let i of data) {
              i.createDate = dayjs(i.createDate).format("YYYY-MM-DD HH:mm:ss");
              i.lastModifyDate = dayjs(i.lastModifyDate).format("YYYY-MM-DD HH:mm:ss");
            }
            this.tableData.data = this.tableData.data.concat(data); //赋值表格数据
            this.loading = false;
          },
          (err) => {
            this.$message({
              message: err,
              type: "warning",
            });
          }
        );
    },
    //条件变化时
    dateChange() {
      // 初始化数据
      this.tableData = {
        data: [],
        title: [],
      };
      this.indexPage = 0;
      this.searchFormData.pageNo = 1;
      if (this.submitTime != null) {
        this.searchFormData.condition.dtBegin = this.submitTime[0];
        this.searchFormData.condition.dtEnd = this.submitTime[1];
      } else {
        this.searchFormData.condition.dtBegin = "";
        this.searchFormData.condition.dtEnd = "";
      }
      this.getTableData();
    },
    // 滚动加载
    loadScroll() {
      if (this.indexPage > 0) {
        let currentDate = dayjs();
        if (currentDate - this.refreshDate > 2000) {
          this.indexPage++;
          this.searchFormData.pageNo = this.indexPage;
          this.getTableData();
          this.refreshDate = currentDate;
        }
      }else {
        this.indexPage++;
      }
    },
    // 删除
    del(row) {
      this.$confirm(
        this.$i18n.t("label.salescloud.salescloud.continuerecover"),
        this.$i18n.t("label_popup_infoz"),
        {
          confirmButtonText: this.$i18n.t("label.ems.confirm"),
          cancelButtonText: this.$i18n.t("pagecreator_button_cancel"),
          type: "warning",
        }
      )
        .then(() => {
          this.$devHttp
            .postFormat(
              `${window.Glod['ccex-dev']}/custom/pc/1.0/post/deleteCustomComp`,
              {
                id: row.id,
              }
            )
            .then(
              (res) => {
                if (res.returnCode == 200) {
                  this.$message.success(this.$i18n.t("label.ems.delete.success"));
                  this.tableData = {
                    data: [],
                    title: [],
                  };
                  this.getTableData();
                }
              },
              (err) => {
                this.$message.warning(err);
              }
            );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$i18n.t("label.delete.cancel"),
          });
        });
    },
    //行内点击
    handleClick() {},
    /** 复制组件 */
    // handleCopyCustomComp() {
    //   this.$http
    //     .post("/devconsole/custom/pc/1.0/post/copyCustomComp", this.form)
    //     .then(
    //       (res) => {
    //         if (res.returnCode == 200) {
    //           this.dialogFormVisible = false;
    //           this.tableData = {
    //             data: [],
    //             title: [],
    //           };
    //           this.getTableData();
    //         }
    //       },
    //       (err) => {
    //         this.$message.warning(err);
    //       }
    //     );
    // },
  },
};
</script>
<style lang="scss" scoped >
.outer {
  height: calc(100vh - 50px - 60px);
  width: 100%;
  position: relative;
  padding: 1vh;
  .formBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    height: 6vh;
    padding: 1vh;
    margin-bottom: 1vh;
    border-radius: 4px;
  }
  .demo-form-inline {
    display: flex;
  }
  .cusTable {
    height: calc(100vh - 50px - 9vh - 5vh);
  }
  .myTable {
    border-radius: 4px;
  }
}
</style>
<style scoped>
.demo-form-inline ::v-deep .el-form-item__label {
  font-size: 12px;
}
.demo-form-inline ::v-deep .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-form-item {
  margin-bottom: 0;
}
</style>
